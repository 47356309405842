import { render, staticRenderFns } from "./TransactionInfo.vue?vue&type=template&id=203ef61c&scoped=true"
import script from "./TransactionInfo.vue?vue&type=script&lang=js"
export * from "./TransactionInfo.vue?vue&type=script&lang=js"
import style0 from "./TransactionInfo.vue?vue&type=style&index=0&id=203ef61c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "203ef61c",
  null
  
)

export default component.exports